import axios from 'axios'
import { BASE_URL, TIMEOUT } from './config'

class CCRequest {
  constructor(baseURL, timeout = 10000) {
    this.instance = axios.create({ baseURL, timeout })

    this.instance.interceptors.response.use(
      (res) => {
        return res.data
      },
      (err) => {
        return err
      }
    )
  }

  request(config) {
    return this.instance.request(config)
  }

  get(config) {
    return this.request({ ...config, method: 'get' })
  }

  post(config) {
    return this.request({ ...config, method: 'post' })
  }
}

// 创建一个CCRequest的实例
const ccRequest = new CCRequest(BASE_URL, TIMEOUT)

// 导出这个实例
export default ccRequest
