import React, { memo } from 'react'
import { useRoutes } from 'react-router-dom'
import AppFooter from './components/app-footer'
import AppHeader from './components/app-header'
import routes from './router'

export default memo(function App() {
  return (
    <div className="app">
      <AppHeader />
      <div>{useRoutes(routes)}</div>
      <AppFooter />
    </div>
  )
})
